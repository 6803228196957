<template>
  <div class="guest-checkin-page">
    <GuestCheckinModal />
  </div>
</template>

<script>
// @ is an alias to /src
import GuestCheckinModal from '@/modules/guests/components/GuestCheckinModal.vue';

export default {
  name: 'Checkin',

  components: {
    GuestCheckinModal,
  },
  layout: 'AppFullPage',
};
</script>
